// Packages
import EventEmitter from 'events'
import { ArgumentNullError } from '@kmb/errors';

import getConfig from './getConfig.js';

/**
 * Basic class to handle all authentication mechanism.
 * Right now, Rumb doesnt' use any third party library to handle authentication
 * process.
 *
 * This class intend to avoid refactoring all other mfe if a choice is made to
 * use a specifc library. If such case append, wrap the choose library in this
 * class.
 */
export default class Authentication extends EventEmitter {
  /**
   * Make a call with basicAuthenticate query and set jwtToken value into local
   * storage.
   *
   * @param {string} email
   * @param {string} password
   */
  async signIn(email, password) {
    if (!email) {
      throw new ArgumentNullError('email');
    }

    if (!password) {
      throw new ArgumentNullError('password');
    }

    // TODO: Prefer to pass everything as args
    const config = getConfig();

    // 1. Make query
    const response = await fetch(config.api, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          query BasicAuthenticate($email:String $password:String) {
            basicAuthenticate(input:{
              email: $email
              password: $password
            }) {
              accessToken
            }
          }
        `,
        variables: {
          email, password,
        },
      }),
    });

    const result = await response.json();

    // 2. Verify
    const { data, errors } = result;
    if (errors && errors.length > 0) {
      console.error({ level: 'error', message: errors[0].message });
      return result;
    }

    window.localStorage.setItem('jwtToken', data.basicAuthenticate.accessToken);
    this.emit('signIn');
    return result;
  }

  /**
   * @returns {boolean}
   */
  async isUserAuthenticated() {
    return !!(await this.getToken());
  }

  async signOut() {
    window.localStorage.removeItem('jwtToken');
    this.emit('signOut');
  }

  async getToken() {
    return window.localStorage.getItem('jwtToken');
  }
}
