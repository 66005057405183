// Parsed from window.MFE_CONFIG as JSON string
let config;

export default function getConfig() {
  if (!config) {
    try {
      config = JSON.parse(atob(window.MFE_CONFIG));
    } catch(error) {
      throw new Error(`MFE_CONFIG is not set or not valid: ${error.message}`);
    }
  }

  return config;
}
