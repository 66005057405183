// packages
import { ArgumentNullError, HttpNotFoundError } from '@kmb/errors';
import getConfig from '../getConfig.js';

/**
 * ! WORKAROUND to handle consulting company members
 * 
 * Returns the organization to put in the global context after login.
 * The organization is always the one the user belongs :
 * - the only one in its list if he belongs in a customer company
 * - the one with the company type "consulting" for a consulting company
 * 
 * @param {*} result 
 *
 * @returns 
 */
const extractOrganizationFromMeQuery = (result) => {
  const orgList = result.data.me.org;

  if (orgList.length === 1) {
    return result.data.me.org[0]
  }

  return result.data.me.org.filter((orga) => (orga?.type === "COMPANY_TYPE_CONSULTING"))[0];
}


export default async function fetchContextFromApi(authToken) {
  if (!authToken) {
    throw new ArgumentNullError("authToken");
  }

  const config = getConfig();

  const response = await fetch(config.api, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify({
      query: `
        query {
          me {
            id
            email
            roles
            localisation
            createdAt
            updatedAt
            org {
              id
              name
              type
            }
          }
        }
      `,
    })
  });

  const result = await response.json();

  if (result.errors && !result.data?.me) {
    throw new HttpNotFoundError(result.errors[0].message);
  }

  return {
    user: result.data.me,
    organization: extractOrganizationFromMeQuery(result)
  };
}
