// packages
import EventEmitter from "events";
import { ArgumentNullError } from "@kmb/errors";
import fetchContextFromApi from "./fetchContextFromApi.js";

const urlRegex = /(\/(?<unscoped>password|signup|login))|(\/org\/(?<orgId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))\/(((?<page>task|profile|setting|bot|kb|customer|dashboard|regulatory|template)(\/(?<entityId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))?)|((?<document>document)((\/(?<documentId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(\/((i\/(?<iteration>.*))|(v\/(?<version>.*))))?)|(\/(?<record>record)(\/(?<recordFolderId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})((\/catalogue\/(?<catalogueId>.*)|(\/board\/(?<recordBoardId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(\/task\/(?<taskInRecordBoardId>.*))?)))?)?))?)|(((?<archive>archive)(\/(?<year>\d*)(\/(?<archivePath>.*))?)?)|((?<cloud>cloud)((\/(?<folderId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})((\/file\/(?<fileInFolderId>.*)|(\/board\/(?<cloudBoardId>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(\/task\/(?<taskInCloudBoardId>.*))?)))?|((\/file\/(?<fileId>.*)))))?)))/

/**
 * Handles MFE global context.
 *
 * @fires Context#userSignedIn User has signed in and the context has been updated
 * @fires Context#userSignedOut User has signed out and the context has been updated
 */
export default class Context extends EventEmitter {
  #auth = undefined;
  #config = undefined;
  #userId = undefined;
  #organizationId = undefined;
  #organizationName = undefined;
  #organizationType = undefined;
  #navigation = {
    catalogueId: undefined,
    boardId: undefined,
    taskId: undefined,
    recordFolderId: undefined,
    templateId: undefined,
    document: {
      id: undefined,
      version: undefined,
      iteration: undefined,
    },
    archive: {
      year: undefined,
      path: undefined,
    },
    cloud: {
      folder: undefined,
      file: undefined,
    },
    orgId: undefined,
    page: undefined,
  };

  constructor(auth, config, user, org) {
    if (!auth) {
      throw new ArgumentNullError("auth");
    }

    if (!config) {
      throw new Error("config is null or undefined");
    }

    super();

    this.#auth = auth;
    this.#auth.on("signIn", () => this.onSignIn());
    this.#auth.on("signOut", () => this.onSignOut());

    this.#config = config;

    this.#userId = user?.id;

    this.#organizationId = org?.id;
    this.#organizationName = org?.name;
    this.#organizationType = org?.type;

    this.#navigation = {
      catalogueId: undefined,
      taskId: undefined,
      boardId: undefined,
      recordFolderId: undefined,
      templateId: undefined,
      document: {
        id: undefined,
        version: undefined,
        iteration: undefined,
      },
      archive: {
        year: undefined,
        path: undefined,
      },
      cloud: {
        folder: undefined,
        file: undefined,
      },
      orgId: undefined,
      page: undefined,
    }
  }

  getOrganizationIdFromURL = () => {
    const path = window.location.pathname;
    const splitedPathname = path.split('/');

    const index = splitedPathname.findIndex((value) => value === 'org')

    if (index === -1 || (index + 1) >= splitedPathname.length) return undefined;

    return splitedPathname[index + 1];
  }

  onUrlChange() {
    const groups = urlRegex.exec(window.location.pathname)?.groups;

    if (!groups) return;

    const {
      page,
      document,
      record,
      archive,
      cloud,
      entityId,
      unscoped,
      documentId,
      iteration,
      version,
      archivePath,
      year,
      folderId,
      fileInFolderId,
      fileId,
      cloudBoardId,
      taskInCloudBoardId,
      recordBoardId,
      taskInRecordBoardId,
      ...other
    } = groups;

    this.#navigation = {
      page: page || archive || cloud || record || document || unscoped,
      taskId: page === "task"
        ? entityId
        : (taskInCloudBoardId || taskInRecordBoardId),
      boardId: cloudBoardId || recordBoardId,
      templateId: page === "template" ? entityId : undefined,
      document: {
        id: documentId,
        iteration: iteration,
        version: version,
      },
      archive: {
        path: archivePath,
        year: year,
      },
      cloud: {
        folder: folderId,
        file: fileId || fileInFolderId,
      },
      ...other
    }

    // event to alert consumming mfe to read data
    this.emit("urlChanged");
  }

  static async createInstance(auth, config) {
    if (!auth) {
      throw new ArgumentNullError("auth");
    }

    if (!config) {
      throw new Error("config is null or undefined");
    }

    if (!(await auth.isUserAuthenticated())) {
      return new Context(auth, config);
    }

    const authToken = await auth.getToken()
    const { user, organization } = await fetchContextFromApi(authToken);

    return new Context(auth, config, user, organization);
  }

  async refreshFromApi() {

    const authToken = await this.auth.getToken()
    const { user, organization } = await fetchContextFromApi(authToken);

    this.#userId = user?.id;

    this.#organizationId = organization?.id;
    this.#organizationName = organization?.name;
    this.#organizationType = organization?.type;

    return;
  }

  async onSignIn() {
    await this.refreshFromApi();
    this.emit("userSignedIn");
  }

  /**
   * @private
   */
  async onSignOut() {
    this.emit("userSignedOut");
  }

  triggerReload() {
    this.emit("reloaded");
  }

  get auth() {
    return this.#auth;
  }

  get config() {
    return this.#config;
  }

  get userId() {
    return this.#userId;
  }

  get organizationId() {
    return this.#organizationId;
  }

  get organizationName() {
    return this.#organizationName;
  }

  get organizationType() {
    return this.#organizationType;
  }

  get navigation() {
    return this.#navigation;
  }
}
